import { useState } from "react";
import { countries } from "./PhoneInput.constants";
import { ICountry, IPhoneInputProps } from "./PhoneInput.models";
import classes from "./PhoneInput.module.css";
import { TextField } from "@fluentui/react/lib/TextField";
import { Callout } from "@fluentui/react/lib/Callout";
import { ComboBox, IComboBoxOption } from "@fluentui/react/lib/ComboBox";

export const PhoneInput = (props: IPhoneInputProps) => {
  const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(false);
  const toggleIsCalloutVisible = () => {
    setIsCalloutVisible(!isCalloutVisible);
  };
  return (
    <div>
      <TextField
        className={props.className}
        onRenderPrefix={() => (
          <div
            tabIndex={0}
            className={classes["country-container"]}
            id="country"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                toggleIsCalloutVisible();
              }
            }}
            onClick={toggleIsCalloutVisible}
          >
            <img
              width={30}
              src={require(`../../assets/svg/flags/${props.countryInfo?.code.toLowerCase()}.svg`)}
              alt={props.countryInfo?.name}
            />
            <div className={classes["phone-code"]}>
              +{props.countryInfo?.phone}
            </div>
          </div>
        )}
        label="Phone"
        title="Phone"
        aria-label="Phone"
        placeholder="Phone"
        value={props.phoneNumber}
        onChange={(_e, value) => {
          props.onPhoneNumberChange && props.onPhoneNumberChange(value || "");
        }}
        maxLength={15}
        errorMessage={props.phoneNumberError}
      />
      {isCalloutVisible && (
        <Callout target={"#country"} onDismiss={toggleIsCalloutVisible}>
          <ComboBox
            tabIndex={0}
            className={classes["country-combobox"]}
            selectedKey={props.countryInfo?.code}
            label="Country"
            allowFreeInput={true}
            autoComplete={"on"}
            options={countries.map((country) => {
              const op: IComboBoxOption = {
                key: `${country.code}`,
                text: country.name,
                data: country,
              };
              return op;
            })}
            onChange={(_e, option) => {
              props.onCountryChange &&
                props.onCountryChange(option?.data as ICountry);
              toggleIsCalloutVisible();
            }}
          />
        </Callout>
      )}
    </div>
  );
};
