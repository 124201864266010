import { useEffect, useReducer } from "react";
import { AppActions, IAppActions } from "./App.actions";
import { IAppState, NavBarOption } from "./App.models";
import { AppReducer } from "./App.reducers";
import { AppService } from "./App.services";
import { countries } from "./components/PhoneInput/PhoneInput.constants";

const getDefaultState = (): IAppState => {
  return {
    selectedNavBarOption: NavBarOption.Home,
    contactprops: {
      name: "",
      email: "",
      phone: "",
      message: "",
      countryInfo: countries.find((c) => c.name === "India") || countries[0],
    },
  };
};

export const useInit = (): {
  state: IAppState;
  actions: IAppActions;
} => {
  const [state, dispatch] = useReducer(AppReducer, getDefaultState());
  const service = new AppService();
  const actions: IAppActions = new AppActions(dispatch, state, service);
  useEffect(() => {
    (async () => {
      actions.initialize();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { state, actions };
};

// const useOnScreen = (ref: React.RefObject<HTMLDivElement>) => {
//   const [isIntersecting, setIntersecting] = useState(false);

//   const observer = useMemo(
//     () =>
//       new IntersectionObserver(
//         ([entry]) => setIntersecting(entry.isIntersecting)
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//       ),
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     [ref]
//   );

//   useEffect(() => {
//     if (!ref || !ref.current) {
//       return;
//     }
//     observer.observe(ref.current);
//     return () => observer.disconnect();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   return isIntersecting;
// };
