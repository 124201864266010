import { ICountry } from "../PhoneInput/PhoneInput.models";

export interface IContact {
  isDarkTheme?: boolean;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  props: IContactProps;
}

export interface IContactProps {
  name: string;
  email: string;
  phone: string;
  message: string;
  countryInfo: ICountry;
  errors?: IContactErrors;
  onContactChange?: (type: ContactChangeType, value: string | ICountry) => void;
  onSubmit?: () => void;
}

export interface IContactErrors {
  name?: string;
  email?: string;
  phone?: string;
  message?: string;
  countryInfo?: string;
  submit?: string;
}

export enum ContactChangeType {
  Name,
  Email,
  Phone,
  Message,
  CountryInfo,
}

export interface IContactInfo {
  url: string;
  name: string;
  image: string;
  alt: string;
}
