import { IContactProps } from "./components/Contact/Contact.models";

export interface IResponseInfo {
  status: number;
  message: string;
  data?: any;
}

export interface IAppService {
  submitContactForm: (data: IContactProps) => Promise<IResponseInfo>;
}

// const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export class AppService implements IAppService {
  submitContactForm = async (data: IContactProps) => {
    const responseInfo: IResponseInfo = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/ContactDetails`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          phone: data.phone,
          message: data.message,
          countryCode: data.countryInfo.code,
        }),
      }
    )
      .then((response: Response) => {
        return {
          status: response.status,
          message: response.statusText,
        };
      })
      .catch((error: any) => {
        return {
          status: error.status,
          message: error.statusText,
        };
      })
      .finally(() => {});
    return responseInfo;
  };
}
