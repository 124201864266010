export const vaildateEmail = (email: string) => {
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (!emailRegex.test(email)) {
        return false;
    } else {
        return true;
    }
}

export const validatePhone = (phone: string) => {
    let phoneRegEx = /^[0-9]\d{5,15}$/;
    if (!phoneRegEx.test(phone)) {
        return false;
    }
    return true;
}