import {
  createTheme,
  ICustomizations,
  mergeStyles,
  ThemeProvider,
} from "@fluentui/react";
import { NavBarItems } from "./App.constants";
import classes from "./App.module.css";
import { NavBar } from "./components/NavBar/NavBar";
import { useInit } from "./App.hooks";
import { useRef } from "react";
import { INavigation, NavBarOption } from "./App.models";
import { Contact } from "./components/Contact/Contact";
import { ContactChangeType } from "./components/Contact/Contact.models";
import { MemorizedHome } from "./components/Home/Home";
import { MemorizedAbout } from "./components/Services/Services";
import { ICountry } from "./components/PhoneInput/PhoneInput.models";

const dark: ICustomizations = {
  settings: {
    theme: createTheme({
      palette: {
        neutralLighterAlt: "#282828",
        neutralLighter: "#313131",
        neutralLight: "#3f3f3f",
        neutralQuaternaryAlt: "#484848",
        neutralQuaternary: "#4f4f4f",
        neutralTertiaryAlt: "#6d6d6d",
        neutralTertiary: "#c8c8c8",
        neutralSecondary: "#d0d0d0",
        neutralPrimaryAlt: "#dadada",
        neutralPrimary: "#ffffff",
        neutralDark: "#f4f4f4",
        black: "#f8f8f8",
        white: "#1f1f1f",
        themePrimary: "#3a96dd",
        themeLighterAlt: "#020609",
        themeLighter: "#091823",
        themeLight: "#112d43",
        themeTertiary: "#235a85",
        themeSecondary: "#3385c3",
        themeDarkAlt: "#4ba0e1",
        themeDark: "#65aee6",
        themeDarker: "#8ac2ec",
        accent: "#3a96dd",
      },
    }),
  },
  scopedSettings: {},
};

const App = () => {
  const { state, actions } = useInit();
  const homeRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const servicesRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const navigation: INavigation[] = [
    {
      navbarOption: NavBarOption.Home,
      ref: homeRef,
    },
    {
      navbarOption: NavBarOption.About,
      ref: aboutRef,
    },
    {
      navbarOption: NavBarOption.Services,
      ref: servicesRef,
    },
    {
      navbarOption: NavBarOption.Contact,
      ref: contactRef,
    },
  ];

  const scrollToSection = (sectionRef: any) => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onNavBarItemClick = (navBarOption: NavBarOption) => {
    actions.setSelectedNavBarOption(navBarOption);
    scrollToSection(
      navigation.find((n) => n.navbarOption === navBarOption)?.ref
    );
  };

  const onContactItemChange = (
    type: ContactChangeType,
    value: string | ICountry
  ) => {
    actions.onContactChange(type, value);
  };

  const onContactSubmit = () => {
    actions.onContactSubmit();
  };

  return (
    <ThemeProvider theme={state.isDarkTheme ? dark.settings.theme : undefined}>
      <div
        className={mergeStyles([
          classes["App"],
          state.isDarkTheme ? classes["dark"] : classes["light"],
        ])}
      >
        <div
          className={mergeStyles([
            classes["header-stickey"],
            state.isDarkTheme
              ? classes["dark-header"]
              : classes["light-header"],
          ])}
        >
          <NavBar
            items={NavBarItems}
            selectedNavBarOption={state.selectedNavBarOption}
            isDarkTheme={state.isDarkTheme}
            onNavBarItemClick={onNavBarItemClick}
            onToggleTheme={actions.setDarkTheme}
          />
        </div>
        <div className={classes["body"]}>
          <div
            className={classes["empty-box-small"]}
            ref={navigation[0].ref}
          ></div>
          <MemorizedHome isDarkMode={state.isDarkTheme} />
          <div ref={navigation[2].ref} className={classes["empty-box"]}></div>
          <MemorizedAbout isDarkMode={state.isDarkTheme} />
          <div ref={navigation[3].ref} className={classes["empty-box"]}></div>
          <Contact
            isDarkTheme={state.isDarkTheme}
            isSubmitting={state.isSubmitting}
            isSubmitted={state.isSubmitted}
            props={{
              ...state.contactprops,
              onContactChange: onContactItemChange,
              onSubmit: onContactSubmit,
            }}
          />
          <div className={classes["empty-box"]}></div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;
