import { TextField } from "@fluentui/react/lib/TextField";
import { ContactChangeType, IContact } from "./Contact.models";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { NavBarOption } from "../../App.models";
import classes from "./Contact.module.css";
import universalClasses from "../../App.module.css";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import x from "../../assets/img/X.png";
import linkedin from "../../assets/img/linkedin.png";
import email from "../../assets/img/email.png";
import { PhoneInput } from "../PhoneInput/PhoneInput";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { SuccessCheck } from "../SuccessCheck/SuccessCheck";

// https://www.flaticon.com/free-icons
export const emailInfo = {
  name: "Email",
  url: "mailto:hindustansoftwares.bizboost@gmail.com",
  image: email,
  alt: "Email",
};

const contactConstants = [
  {
    url: "https://www.linkedin.com/company/hindustan-softwares-bizboost/",
    name: "LinkedIn",
    image: linkedin,
    alt: "LinkedIn",
  },
  // {
  //   url: "https://github.com/aayushgupta7995",
  //   name: "GitHub",
  //   image: github,
  //   alt: "GitHub",
  // },
  {
    url: "https://x.com/HS_BizBoost",
    name: "Twitter",
    image: x,
    alt: "X",
  },
];

export const Contact = ({
  isDarkTheme,
  isSubmitting,
  isSubmitted,
  props,
}: IContact) => {
  const Form = () => {
    return (
      <div
        className={mergeStyles([
          classes["form-info"],
          isDarkTheme ? classes["form-info-dark"] : classes["form-info-light"],
        ])}
      >
        {isSubmitted ? (
          <div className={classes["success-container"]}>
            <div className={universalClasses["heading-lvl-3"]}>
              Thank you for reaching out!
            </div>
            <SuccessCheck />
            <p className={universalClasses["paragraph-wrapped"]}>
              We have received your message and will get back to you as soon as
              possible. Please check your inbox, we have sent you a
              confirmation.
            </p>
          </div>
        ) : (
          <>
            <TextField
              label="Name"
              title="Name"
              aria-label="Name"
              placeholder="Name"
              className={classes["basic-info-item"]}
              value={props.name}
              onChange={(_e, value) => {
                props.onContactChange &&
                  props.onContactChange(ContactChangeType.Name, value || "");
              }}
              maxLength={100}
              errorMessage={props.errors?.name}
              required={true}
            />
            <TextField
              label="Email"
              title="Email"
              aria-label="Email"
              placeholder="Email"
              className={classes["basic-info-item"]}
              value={props.email}
              onChange={(_e, value) => {
                props.onContactChange &&
                  props.onContactChange(ContactChangeType.Email, value || "");
              }}
              maxLength={100}
              errorMessage={props.errors?.email}
              required={true}
            />
            <PhoneInput
              className={classes["basic-info-item"]}
              countryInfo={props.countryInfo}
              phoneNumber={props.phone}
              onCountryChange={(countryInfo) => {
                props.onContactChange &&
                  props.onContactChange(
                    ContactChangeType.CountryInfo,
                    countryInfo || ""
                  );
              }}
              onPhoneNumberChange={(PhoneNumber) => {
                props.onContactChange &&
                  props.onContactChange(
                    ContactChangeType.Phone,
                    PhoneNumber || ""
                  );
              }}
              phoneNumberError={props.errors?.phone}
            />
            <TextField
              label="Message"
              title="Message"
              aria-label="Message"
              placeholder="Message"
              className={classes["basic-info-item"]}
              value={props.message}
              onChange={(_e, value) => {
                props.onContactChange &&
                  props.onContactChange(ContactChangeType.Message, value || "");
              }}
              multiline={true}
              maxLength={1000}
              errorMessage={props.errors?.message}
              required={true}
            />
            {isSubmitting ? (
              <Spinner className={classes["spinner"]} />
            ) : (
              <>
                <PrimaryButton
                  className={
                    isDarkTheme
                      ? classes["submit-button-dark"]
                      : classes["submit-button"]
                  }
                  text="Submit"
                  onClick={() => props.onSubmit && props.onSubmit()}
                />
                {props.errors?.submit && (
                  <div className={classes["error-string"]}>
                    {props.errors.submit}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const ContactCard = () => {
    return (
      <div
        className={mergeStyles([
          classes["contact-info"],
          isDarkTheme ? classes["dark"] : classes["light"],
        ])}
      >
        <div>
          <h2>Email Us</h2>
          <a href={emailInfo.url} target="_blank" rel="noreferrer">
            <img alt={emailInfo.alt} src={emailInfo.image} width={50} />
            <p>{emailInfo.name}</p>
          </a>
        </div>
        <h2>Follow Us</h2>
        <p>
          Stay connected with us on social media for the latest updates and
          insights.
        </p>
        <div className={classes["social-icons"]}>
          {contactConstants.map((item, index) => {
            return (
              <a href={item.url} target="_blank" rel="noreferrer" key={index}>
                <img alt={item.alt} src={item.image} width={50} />
                <p>{item.name}</p>
              </a>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2
        id={NavBarOption.Contact}
        className={universalClasses["heading-lvl-1"]}
      >
        Contact Us
      </h2>
      <p
        className={mergeStyles([
          universalClasses["paragraph"],
          universalClasses["center-text"],
        ])}
      >
        Ready to take your business to the next level? Get in touch with
        Hindustan Softwares today and let's start building something amazing
        together.
      </p>
      <div className={classes["contact-container"]}>
        {Form()}
        {ContactCard()}
      </div>
    </div>
  );
};
