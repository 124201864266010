import { INavbarProps } from "./NavBar.models";
import classes from "./NavBar.module.css";
import logo from "../../assets/img/logo.png";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { Icon } from "@fluentui/react/lib/Icon";
import { mergeStyles } from "@fluentui/react/lib/Styling";

const labels = {
  logoDiscription: "Hindustan Softwares",
};

export const NavBar = (props: INavbarProps) => {
  return (
    <div className={classes["container"]}>
      <div className={classes["nav-items-container"]}>
        <img
          aria-label={labels.logoDiscription}
          title={labels.logoDiscription}
          src={logo}
          alt="logo"
          className={classes["logo"]}
        />
        {props.items.map((item, index) => {
          return (
            <div
              tabIndex={0}
              className={mergeStyles([
                classes["nav-item"],
                item.navbarOption === props.selectedNavBarOption
                  ? classes["nav-item-selected"]
                  : undefined,
              ])}
              key={index}
              onClick={() => props.onNavBarItemClick(item.navbarOption)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props.onNavBarItemClick(item.navbarOption);
                }
              }}
            >
              {item.label}
              <hr
                className={
                  item.navbarOption === props.selectedNavBarOption
                    ? classes["nav-line-selected"]
                    : classes["nav-line"]
                }
              ></hr>
            </div>
          );
        })}
      </div>
      <div className={classes["toggle-container"]}>
        <Toggle
          aria-label={"Theme"}
          title={"Theme"}
          className={classes["theme-toggle"]}
          checked={props.isDarkTheme || false}
          onChange={(_e, checked) => {
            props.onToggleTheme && props.onToggleTheme(checked || false);
          }}
        />
        <Icon iconName={props.isDarkTheme ? "moon" : "sun"} />
      </div>
    </div>
  );
};
