import { APP_ACTIONS, IAppDispatchActions } from "./App.actions";
import { IAppState } from "./App.models";

export const AppReducer = (
  state: IAppState,
  action: IAppDispatchActions
): IAppState => {
  switch (action.type) {
    case APP_ACTIONS.SET_SELECTED_NAVBAR_OPTION:
      return { ...state, selectedNavBarOption: action.data };
    case APP_ACTIONS.SET_CONTACT_PROPS:
      return { ...state, contactprops: action.data };
    case APP_ACTIONS.SET_DARK_THEME:
      return { ...state, isDarkTheme: action.data };
    case APP_ACTIONS.SET_IS_SUBMITTING:
      return { ...state, isSubmitting: action.data };
    case APP_ACTIONS.SET_IS_SUBMITTED:
      return { ...state, isSubmitted: action.data };
    default:
      return state;
  }
};
