import { IAppState, NavBarOption } from "./App.models";
import { IAppService } from "./App.services";
import {
  ContactChangeType,
  IContactProps,
} from "./components/Contact/Contact.models";
import {
  vaildateEmail,
  validatePhone,
} from "./components/Contact/Contact.utils";
import { ICountry } from "./components/PhoneInput/PhoneInput.models";

export enum APP_ACTIONS {
  SET_SELECTED_NAVBAR_OPTION,
  SET_CONTACT_PROPS,
  SET_DARK_THEME,
  SET_IS_SUBMITTING,
  SET_IS_SUBMITTED,
}

export type IAppDispatchActions =
  | {
      type: APP_ACTIONS.SET_SELECTED_NAVBAR_OPTION;
      data: NavBarOption;
    }
  | {
      type: APP_ACTIONS.SET_CONTACT_PROPS;
      data: IContactProps;
    }
  | {
      type: APP_ACTIONS.SET_DARK_THEME;
      data: boolean;
    }
  | {
      type: APP_ACTIONS.SET_IS_SUBMITTING;
      data: boolean;
    }
  | {
      type: APP_ACTIONS.SET_IS_SUBMITTED;
      data: boolean;
    };

export interface IAppActions {
  initialize: () => void;
  setSelectedNavBarOption: (data: NavBarOption) => void;
  onContactChange: (type: ContactChangeType, value: string | ICountry) => void;
  onContactSubmit: () => Promise<void>;
  setDarkTheme: (isDarkTheme: boolean) => void;
}

export class AppActions implements IAppActions {
  private readonly dispatch: React.Dispatch<IAppDispatchActions>;
  private readonly state: IAppState;
  private readonly service: IAppService;

  constructor(
    dispatch: React.Dispatch<IAppDispatchActions>,
    state: IAppState,
    service: IAppService
  ) {
    this.dispatch = dispatch;
    this.state = state;
    this.service = service;
  }

  initialize = () => {
    // Add your initialization logic here
    // const savedTheme = localStorage.getItem("theme");
    let savedTheme = "light";
    let isDarkTheme = false;
    if (savedTheme) {
      isDarkTheme = savedTheme === "dark";
    } else {
      isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
    this.dispatch({ type: APP_ACTIONS.SET_DARK_THEME, data: isDarkTheme });
  };

  setSelectedNavBarOption = (data: NavBarOption) => {
    this.dispatch({ type: APP_ACTIONS.SET_SELECTED_NAVBAR_OPTION, data });
  };

  onContactChange = (type: ContactChangeType, value: string | ICountry) => {
    const contactProps = this.state.contactprops;
    if (!contactProps.errors) {
      contactProps.errors = {};
    }
    switch (type) {
      case ContactChangeType.Name:
        contactProps.name = value as string;
        contactProps.errors.name = undefined;
        break;
      case ContactChangeType.Email:
        contactProps.email = value as string;
        contactProps.errors.email = undefined;
        break;
      case ContactChangeType.Phone:
        contactProps.phone = value as string;
        break;
      case ContactChangeType.Message:
        contactProps.message = value as string;
        contactProps.errors.message = undefined;
        break;
      case ContactChangeType.CountryInfo:
        contactProps.countryInfo = value as ICountry;
        break;
    }
    this.dispatch({ type: APP_ACTIONS.SET_CONTACT_PROPS, data: contactProps });
  };

  onContactSubmit = async () => {
    const isValid = this.validateContactProps(this.state.contactprops);
    if (!isValid) {
      return;
    }
    // Add your submit logic here
    this.dispatch({ type: APP_ACTIONS.SET_IS_SUBMITTING, data: true });
    const responseInfo = await this.service.submitContactForm(
      this.state.contactprops
    );
    this.dispatch({ type: APP_ACTIONS.SET_IS_SUBMITTING, data: false });
    if (responseInfo.status === 201) {
      this.dispatch({ type: APP_ACTIONS.SET_IS_SUBMITTED, data: true });
    } else {
      // Add your failure logic
      const contactProps = this.state.contactprops;
      if (!contactProps.errors) {
        contactProps.errors = {};
      }
      contactProps.errors.submit = "Failed to submit the form";
      this.dispatch({
        type: APP_ACTIONS.SET_CONTACT_PROPS,
        data: contactProps,
      });
    }
  };

  validateContactProps = (contactProps: IContactProps) => {
    let isValid = true;
    contactProps.errors = {};
    if (!contactProps.name) {
      contactProps.errors!.name = "Name is required";
      isValid = false;
    }
    if (!contactProps.email) {
      contactProps.errors!.email = "Email is required";
      isValid = false;
    } else if (!vaildateEmail(contactProps.email)) {
      contactProps.errors!.email = "Please enter a valid email id!";
      isValid = false;
    }
    if (contactProps.phone.length > 0 && !validatePhone(contactProps.phone)) {
      contactProps.errors.phone = "Please enter a valid phone number!";
      isValid = false;
    }
    if (!contactProps.message) {
      contactProps.errors!.message = "Message is required";
      isValid = false;
    }
    this.dispatch({ type: APP_ACTIONS.SET_CONTACT_PROPS, data: contactProps });
    return isValid;
  };

  setDarkTheme = (isDarkTheme: boolean) => {
    if (isDarkTheme) {
      document.body.classList.add("dark-theme");
      // localStorage.setItem("theme", "dark");
    } else {
      document.body.classList.remove("dark-theme");
      // localStorage.setItem("theme", "light");
    }
    this.dispatch({ type: APP_ACTIONS.SET_DARK_THEME, data: isDarkTheme });
  };
}
