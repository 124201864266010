import { IAboutProps, ICardInfo } from "./Services.models";
import universalClasses from "../../App.module.css";
import { NavBarOption } from "../../App.models";
import Tilt from "react-parallax-tilt";
import classes from "./Services.module.css";
import mobile from "../../assets/img/mobile.jpeg";
import website from "../../assets/img/website.jpeg";
import api from "../../assets/img/api.jpeg";
import ai from "../../assets/img/ai.jpeg";
import backend from "../../assets/img/backend.jpeg";
import game from "../../assets/img/game.jpeg";
import React from "react";

const About = (props: IAboutProps) => {
  const cardInfo: ICardInfo[] = [
    {
      title: "Mobile App Development",
      description:
        "In today's fast-paced world, a mobile app can be a game-changer for your business. At Hindustan Softwares, we specialize in creating intuitive, user-friendly, and feature-rich mobile applications. Our mobile app development team ensures your app not only looks great but performs flawlessly across all devices.",
      image: mobile,
    },
    {
      title: "Web App Development",
      description:
        "Your website is the digital face of your business. Our web app development services ensure your website is fast, responsive, and engaging. From e-commerce platforms to custom web applications, we design and develop solutions that captivate your audience and drive results.",
      image: website,
    },
    {
      title: "AI Integration",
      description:
        "Artificial Intelligence is the future of technology, and at Hindustan Softwares, we integrate AI solutions to help your business stay ahead of the curve. Whether it's chatbots, predictive analytics, or machine learning models, our AI integration services enhance your operations and improve customer experience.",
      image: ai,
    },
    {
      title: "API Integration",
      description:
        "Seamless connectivity between your applications and third-party services is crucial. Our API integration services ensure your systems communicate effectively, enhancing functionality and streamlining processes. We specialize in integrating various APIs to make your operations smoother and more efficient.",
      image: api,
    },
    {
      title: "Backend Development",
      description:
        "The backbone of any application lies in its backend. Our backend development team builds robust, scalable, and secure server-side solutions. From database management to server-side logic, we ensure your application runs seamlessly and efficiently.",
      image: backend,
    },
    {
      title: "Game Development",
      description:
        "Dive into the world of interactive entertainment with our comprehensive game development services. At Hindustan Softwares, we specialize in creating captivating and immersive gaming experiences for a wide range of platforms. Our game development team leverages cutting-edge technology and innovative design to bring your game concepts to life. From concept to launch, we ensure your game not only looks stunning but also delivers engaging gameplay and seamless performance.",
      image: game,
    },
  ];

  return (
    <div>
      <div
        aria-level={3}
        role={"heading"}
        id={NavBarOption.Home}
        className={universalClasses["heading-lvl-1"]}
      >
        Our Services
      </div>
      <div className={classes["service-container"]}>
        {cardInfo.map((card, index) => (
          <Tilt>
            <div className={classes["service-card"]}>
              <img
                // aria-label={labels.logoDiscription}
                // title={labels.logoDiscription}
                src={card.image}
                alt="logo"
                className={classes["service-card-image"]}
              />
              <div
                aria-level={3}
                role={"heading"}
                className={universalClasses["heading-lvl-3"]}
              >
                {card.title}
              </div>
              <p className={universalClasses["paragraph"]}>
                {card.description}
              </p>
            </div>
          </Tilt>
        ))}
      </div>
    </div>
  );
};

export const MemorizedAbout = React.memo(About);
