import { IContactProps } from "./components/Contact/Contact.models";

export interface IAppState {
  selectedNavBarOption: NavBarOption;
  contactprops: IContactProps;
  isDarkTheme?: boolean;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
}

export enum NavBarOption {
  Home = "Home",
  About = "About",
  Services = "Services",
  Contact = "Contact",
}

export interface INavigation {
  navbarOption: NavBarOption;
  ref?: React.RefObject<HTMLDivElement>;
  isVisible?: boolean;
}
