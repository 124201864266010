import { NavBarOption } from "./App.models";
import { INavBarItem } from "./components/NavBar/NavBar.models";

export const NavBarItems: INavBarItem[] = [
  {
    label: "Home",
    navbarOption: NavBarOption.Home,
  },
  // {
  //   label: "About",
  //   navbarOption: NavBarOption.About
  // },
  {
    label: "Services",
    navbarOption: NavBarOption.Services,
  },
  {
    label: "Contact",
    navbarOption: NavBarOption.Contact,
  },
];
