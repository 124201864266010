import { NavBarOption } from "../../App.models";
import { IHomeProps } from "./Home.models";
import universalClasses from "../../App.module.css";
import logo from "../../assets/img/logo.png";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import classes from "./Home.module.css";
import React from "react";

const Home = (props: IHomeProps) => {
  return (
    <div className={universalClasses["container"]}>
      <div
        aria-level={2}
        role={"heading"}
        id={NavBarOption.Home}
        className={universalClasses["heading-lvl-1"]}
      >
        Welcome
      </div>
      <div
        aria-level={3}
        role={"heading"}
        className={universalClasses["heading-lvl-2"]}
      >
        to Hindustan Softwares
      </div>
      <img src={logo} className={universalClasses["App-logo"]} alt="logo" />
      <p
        className={mergeStyles([
          universalClasses["paragraph"],
          classes["description"],
        ])}
      > 
        At Hindustan Softwares, we transform your digital dreams into reality.
        Our passion for technology and innovation drives us to deliver
        exceptional software solutions that cater to your unique needs. Whether
        you're looking for mobile app development, web app solutions, AI
        integration, API integration, game development, or backend development,
        we have the expertise to bring your vision to life.
      </p>
    </div>
  );
};

export const MemorizedHome = React.memo(Home);
